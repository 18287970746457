import { graphql, type HeadFC, type PageProps } from 'gatsby';
import React, { type FC } from 'react';

import { ContentWithHeader } from '@/components/content/content-with-header';
import { ContentWithNavigation } from '@/components/content/content-with-navigation';
import { AppHead } from '@/components/head';
import { TableOfContents } from '@/components/navigations/table-of-contents';

interface DefaultPageProps {
    page: GraphqlSelect.Page<'name' | 'body' | 'metaTitle' | 'metaDescription'>;
}

type DefaultPageContext = GraphqlSelect.Page<'id'>;

const DefaultPage: FC<PageProps<DefaultPageProps>> = ({ data: { page } }) => {
    return (
        <article>
            <ContentWithHeader
                theme="gray"
                breadcrumbs={[{ name: page.name }]}
                style={{ marginBottom: 'clamp(18px, 5.6vw, 72px)' }}
            >
                <h1 className="fs-56">{page.name}</h1>
            </ContentWithHeader>

            <ContentWithNavigation
                className="container"
                left={page.body.html}
                right={<TableOfContents headers={page.body.headers} />}
            />
        </article>
    );
};

export const Head: HeadFC<DefaultPageProps, DefaultPageContext> = ({
    data: { page },
    pageContext: { id },
    location: { pathname },
}) => {
    return (
        <AppHead
            index
            title={page.metaTitle}
            description={page.metaDescription}
            canonical={pathname}
            ogImage={{ id, type: 'pages' }}
        />
    );
};

export const query = graphql`
    query DefaultPage($id: String!) {
        page: pages(id: { eq: $id }) {
            name
            metaTitle
            metaDescription

            body {
                html
                headers
            }
        }
    }
`;

export default DefaultPage;
